.container {
    display: flex;
    flex-wrap: wrap;
}

.column {
    width: 100%;
}

.mask {
    background-color: var(--blue);
    background-image: url('/images/group-mask.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.details, .body {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 85%;
}

.body {
    max-width: 360px;
    padding: 1rem 0;
}

.details {
    padding: 3rem 0;
    text-align: center;
}

.content {
    background-color: var(--white);
}

@media(min-width: 72em) {

    .column {
        min-height: calc(100vh - 158px);
        width: 50%;
    }

    .details, .body  {
        display: flex;
        flex-direction: column;
        margin: 4rem 0 4rem 0;
        text-align: left;
        width: auto;
    }

    .body  {
        align-items: flex-start;
        margin: 6rem 0 4rem 4rem;
    }

    .details {
        align-items: flex-end;
        text-align: left;
        margin: 4rem 4rem 4rem auto;
    }
}