.ButtonGroup {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
}

.ButtonGroup > *:not(:last-child) {
  margin-right: 0.5rem;
}
