.Layout {
  position: relative;
  margin: 0 auto;
  width: 100%;
  /* padding: 1rem 0; */
  min-height: calc(100vh - 5rem);
}

.layout--internal {
  margin: 0 auto;
  width: 100%;
}

.main { }
